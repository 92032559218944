import React from "react";

const IconLogo = () => (
  <svg
    width="741"
    height="514"
    viewBox="0 0 741 514"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M409 73H229.5C130.365 73 50 153.365 50 252.5V252.5C50 351.635 130.365 432 229.5 432H409"
      stroke="#5552E6"
      strokeWidth="100"
      strokeLinecap="round"
    />
    <path
      d="M174.725 320V295.025H202.85V220.775H175.4V198.5L186.2 197.6C191.75 197.15 195.875 195.95 198.575 194C201.275 192.05 203.15 188.525 204.2 183.425L205.325 178.25H232.55V295.025H260.675V320H174.725ZM332.185 323.375C323.785 323.375 315.985 321.875 308.785 318.875C301.735 315.725 295.66 311.15 290.56 305.15C285.61 299 281.71 291.275 278.86 281.975C276.01 272.525 274.585 261.575 274.585 249.125C274.585 236.675 276.01 225.8 278.86 216.5C281.71 207.05 285.685 199.325 290.785 193.325C295.885 187.175 301.96 182.6 309.01 179.6C316.06 176.45 323.785 174.875 332.185 174.875C340.585 174.875 348.31 176.45 355.36 179.6C362.41 182.6 368.485 187.175 373.585 193.325C378.685 199.325 382.66 207.05 385.51 216.5C388.36 225.8 389.785 236.675 389.785 249.125C389.785 261.575 388.36 272.525 385.51 281.975C382.66 291.275 378.685 299 373.585 305.15C368.485 311.15 362.41 315.725 355.36 318.875C348.31 321.875 340.585 323.375 332.185 323.375ZM332.185 296.15C335.935 296.15 339.46 295.475 342.76 294.125C346.21 292.625 349.135 290.15 351.535 286.7C354.085 283.1 356.035 278.3 357.385 272.3C358.885 266.15 359.635 258.425 359.635 249.125C359.635 239.825 358.885 232.175 357.385 226.175C356.035 220.025 354.085 215.225 351.535 211.775C349.135 208.175 346.21 205.7 342.76 204.35C339.46 202.85 335.935 202.1 332.185 202.1C328.435 202.1 324.835 202.85 321.385 204.35C318.085 205.7 315.16 208.175 312.61 211.775C310.21 215.225 308.26 220.025 306.76 226.175C305.41 232.175 304.735 239.825 304.735 249.125C304.735 258.425 305.41 266.15 306.76 272.3C308.26 278.3 310.21 283.1 312.61 286.7C315.16 290.15 318.085 292.625 321.385 294.125C324.835 295.475 328.435 296.15 332.185 296.15ZM412.249 320V295.025H440.374V220.775H412.924V198.5L423.724 197.6C429.274 197.15 433.399 195.95 436.099 194C438.799 192.05 440.674 188.525 441.724 183.425L442.849 178.25H470.074V295.025H498.199V320H412.249Z"
      fill="#7A77FB"
    />
    <path
      d="M569.709 323.375C561.309 323.375 553.509 321.875 546.309 318.875C539.259 315.725 533.184 311.15 528.084 305.15C523.134 299 519.234 291.275 516.384 281.975C513.534 272.525 512.109 261.575 512.109 249.125C512.109 236.675 513.534 225.8 516.384 216.5C519.234 207.05 523.209 199.325 528.309 193.325C533.409 187.175 539.484 182.6 546.534 179.6C553.584 176.45 561.309 174.875 569.709 174.875C578.109 174.875 585.834 176.45 592.884 179.6C599.934 182.6 606.009 187.175 611.109 193.325C616.209 199.325 620.184 207.05 623.034 216.5C625.884 225.8 627.309 236.675 627.309 249.125C627.309 261.575 625.884 272.525 623.034 281.975C620.184 291.275 616.209 299 611.109 305.15C606.009 311.15 599.934 315.725 592.884 318.875C585.834 321.875 578.109 323.375 569.709 323.375ZM569.709 296.15C573.459 296.15 576.984 295.475 580.284 294.125C583.734 292.625 586.659 290.15 589.059 286.7C591.609 283.1 593.559 278.3 594.909 272.3C596.409 266.15 597.159 258.425 597.159 249.125C597.159 239.825 596.409 232.175 594.909 226.175C593.559 220.025 591.609 215.225 589.059 211.775C586.659 208.175 583.734 205.7 580.284 204.35C576.984 202.85 573.459 202.1 569.709 202.1C565.959 202.1 562.359 202.85 558.909 204.35C555.609 205.7 552.684 208.175 550.134 211.775C547.734 215.225 545.784 220.025 544.284 226.175C542.934 232.175 542.259 239.825 542.259 249.125C542.259 258.425 542.934 266.15 544.284 272.3C545.784 278.3 547.734 283.1 550.134 286.7C552.684 290.15 555.609 292.625 558.909 294.125C562.359 295.475 565.959 296.15 569.709 296.15ZM649.774 320V295.025H677.899V220.775H650.449V198.5L661.249 197.6C666.799 197.15 670.924 195.95 673.624 194C676.324 192.05 678.199 188.525 679.249 183.425L680.374 178.25H707.599V295.025H735.724V320H649.774Z"
      fill="#5552E6"
    />
    <path
      d="M523.25 479.25C517.65 479.25 512.45 478.25 507.65 476.25C502.95 474.15 498.9 471.1 495.5 467.1C492.2 463 489.6 457.85 487.7 451.65C485.8 445.35 484.85 438.05 484.85 429.75C484.85 421.45 485.8 414.2 487.7 408C489.6 401.7 492.25 396.55 495.65 392.55C499.05 388.45 503.1 385.4 507.8 383.4C512.5 381.3 517.65 380.25 523.25 380.25C528.85 380.25 534 381.3 538.7 383.4C543.4 385.4 547.45 388.45 550.85 392.55C554.25 396.55 556.9 401.7 558.8 408C560.7 414.2 561.65 421.45 561.65 429.75C561.65 438.05 560.7 445.35 558.8 451.65C556.9 457.85 554.25 463 550.85 467.1C547.45 471.1 543.4 474.15 538.7 476.25C534 478.25 528.85 479.25 523.25 479.25ZM523.25 461.1C525.75 461.1 528.1 460.65 530.3 459.75C532.6 458.75 534.55 457.1 536.15 454.8C537.85 452.4 539.15 449.2 540.05 445.2C541.05 441.1 541.55 435.95 541.55 429.75C541.55 423.55 541.05 418.45 540.05 414.45C539.15 410.35 537.85 407.15 536.15 404.85C534.55 402.45 532.6 400.8 530.3 399.9C528.1 398.9 525.75 398.4 523.25 398.4C520.75 398.4 518.35 398.9 516.05 399.9C513.85 400.8 511.9 402.45 510.2 404.85C508.6 407.15 507.3 410.35 506.3 414.45C505.4 418.45 504.95 423.55 504.95 429.75C504.95 435.95 505.4 441.1 506.3 445.2C507.3 449.2 508.6 452.4 510.2 454.8C511.9 457.1 513.85 458.75 516.05 459.75C518.35 460.65 520.75 461.1 523.25 461.1ZM576.627 477V460.35H595.377V410.85H577.077V396L584.277 395.4C587.977 395.1 590.727 394.3 592.527 393C594.327 391.7 595.577 389.35 596.277 385.95L597.027 382.5H615.177V460.35H633.927V477H576.627Z"
      fill="#5552E6"
    />
    <path
      d="M681.6 479.25C676 479.25 670.8 478.25 666 476.25C661.3 474.15 657.25 471.1 653.85 467.1C650.55 463 647.95 457.85 646.05 451.65C644.15 445.35 643.2 438.05 643.2 429.75C643.2 421.45 644.15 414.2 646.05 408C647.95 401.7 650.6 396.55 654 392.55C657.4 388.45 661.45 385.4 666.15 383.4C670.85 381.3 676 380.25 681.6 380.25C687.2 380.25 692.35 381.3 697.05 383.4C701.75 385.4 705.8 388.45 709.2 392.55C712.6 396.55 715.25 401.7 717.15 408C719.05 414.2 720 421.45 720 429.75C720 438.05 719.05 445.35 717.15 451.65C715.25 457.85 712.6 463 709.2 467.1C705.8 471.1 701.75 474.15 697.05 476.25C692.35 478.25 687.2 479.25 681.6 479.25ZM681.6 461.1C684.1 461.1 686.45 460.65 688.65 459.75C690.95 458.75 692.9 457.1 694.5 454.8C696.2 452.4 697.5 449.2 698.4 445.2C699.4 441.1 699.9 435.95 699.9 429.75C699.9 423.55 699.4 418.45 698.4 414.45C697.5 410.35 696.2 407.15 694.5 404.85C692.9 402.45 690.95 400.8 688.65 399.9C686.45 398.9 684.1 398.4 681.6 398.4C679.1 398.4 676.7 398.9 674.4 399.9C672.2 400.8 670.25 402.45 668.55 404.85C666.95 407.15 665.65 410.35 664.65 414.45C663.75 418.45 663.3 423.55 663.3 429.75C663.3 435.95 663.75 441.1 664.65 445.2C665.65 449.2 666.95 452.4 668.55 454.8C670.25 457.1 672.2 458.75 674.4 459.75C676.7 460.65 679.1 461.1 681.6 461.1Z"
      fill="#7A77FB"
    />
    <path
      d="M488.15 128V111.35H506.9V61.85H488.6V47L495.8 46.4C499.5 46.1 502.25 45.3 504.05 44C505.85 42.7 507.1 40.35 507.8 36.95L508.55 33.5H526.7V111.35H545.45V128H488.15Z"
      fill="#5552E6"
    />
    <path
      d="M593.123 130.25C587.523 130.25 582.323 129.25 577.523 127.25C572.823 125.15 568.773 122.1 565.373 118.1C562.073 114 559.473 108.85 557.573 102.65C555.673 96.35 554.723 89.05 554.723 80.75C554.723 72.45 555.673 65.2 557.573 59C559.473 52.7 562.123 47.55 565.523 43.55C568.923 39.45 572.973 36.4 577.673 34.4C582.373 32.3 587.523 31.25 593.123 31.25C598.723 31.25 603.873 32.3 608.573 34.4C613.273 36.4 617.323 39.45 620.723 43.55C624.123 47.55 626.773 52.7 628.673 59C630.573 65.2 631.523 72.45 631.523 80.75C631.523 89.05 630.573 96.35 628.673 102.65C626.773 108.85 624.123 114 620.723 118.1C617.323 122.1 613.273 125.15 608.573 127.25C603.873 129.25 598.723 130.25 593.123 130.25ZM593.123 112.1C595.623 112.1 597.973 111.65 600.173 110.75C602.473 109.75 604.423 108.1 606.023 105.8C607.723 103.4 609.023 100.2 609.923 96.2C610.923 92.1 611.423 86.95 611.423 80.75C611.423 74.55 610.923 69.45 609.923 65.45C609.023 61.35 607.723 58.15 606.023 55.85C604.423 53.45 602.473 51.8 600.173 50.9C597.973 49.9 595.623 49.4 593.123 49.4C590.623 49.4 588.223 49.9 585.923 50.9C583.723 51.8 581.773 53.45 580.073 55.85C578.473 58.15 577.173 61.35 576.173 65.45C575.273 69.45 574.823 74.55 574.823 80.75C574.823 86.95 575.273 92.1 576.173 96.2C577.173 100.2 578.473 103.4 580.073 105.8C581.773 108.1 583.723 109.75 585.923 110.75C588.223 111.65 590.623 112.1 593.123 112.1ZM646.5 128V111.35H665.25V61.85H646.95V47L654.15 46.4C657.85 46.1 660.6 45.3 662.4 44C664.2 42.7 665.45 40.35 666.15 36.95L666.9 33.5H685.05V111.35H703.8V128H646.5Z"
      fill="#7A77FB"
    />
  </svg>
);

export default IconLogo;
